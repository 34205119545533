// @ts-nocheck
/* eslint-disable react-hooks/rules-of-hooks */

import swell from 'swell-js';
import type { StateCreator } from 'zustand';

import type { CurrencySlice } from './checkoutStoreTypes';
import type { SetCurrencyProps } from './checkoutTypes';
// eslint-disable-next-line import/no-cycle
import { createCartSlice } from './createCartSlice';
import { createSettingSlice } from './createSettingSlice';

export const createCurrencySlice: StateCreator<CurrencySlice> = (set) => ({
  currencyList: [],
  currency: '',
  symbol: '',
  shouldShowDefaultCurrency: false,

  /** function that is responsible for setting the currency and symbol in the currency slice of the state.
   * @param {string} currency
   * @param {string} symbol
   * @returns {Promise<void>}
   */
  setCurrency: async ({ currency, symbol }: SetCurrencyProps) => {
    try {
      const res = await swell.currency.select(currency);
      if (res) {
        await createCartSlice(set).fetchCart();
        set((prevState) => ({
          ...prevState,
          currency,
          symbol,
        }));
      } else throw new Error('Unable to select currency now.');
    } catch (error) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
    }
  },

  /** This function is responsible for fetching the list of available currencies from the `swell` API.
   * @param {string} currency
   */
  getCurrencies: async (currency: string) => {
    try {
      const res = await swell.currency.list();
      if (res) {
        set((prevState) => ({
          ...prevState,
          currencyList: res,
        }));
        const selectedCurrency = currency || swell.currency.selected();
        // !NOTE: update logic accordingly to provide currency change option on checkout when needed
        // if (selectedCurrency !== cart.currency) {
        //   await swell.currency.select(cart.currency);
        // }
        // @ts-ignore
        const currencyData = res.filter(
          (el: any) => el?.code === selectedCurrency
        );
        const selectedSymbol =
          currencyData?.at(0)?.symbol || currencyData?.at(0)?.code;
        set((prevState) => ({
          ...prevState,
          symbol: selectedSymbol,
          currency: selectedCurrency as string,
        }));
      } else {
        throw new Error('Unable to fetch currency list');
      }
    } catch (error) {
      set((prevState) => ({
        ...prevState,
        // @ts-ignore
        error: error?.message as any as string,
      }));
    }
  },

  setShouldShowDefaultCurrency: (val: boolean) => {
    set((prevState) => ({
      ...prevState,
      shouldShowDefaultCurrency: val,
    }));
  },
});
