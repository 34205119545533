import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import type {
  AddressSlice,
  AuthSlice,
  CartSlice,
  CurrencySlice,
  PaymentSlice,
  SettingSlice,
} from './checkoutStoreTypes';
import { createAuthSlice } from './createAuthSlice';
import { createCartSlice } from './createCartSlice';
import { createCurrencySlice } from './createCurrencySlice';
import { createPaymentSlice } from './createPaymentSlice';
import { createSettingSlice } from './createSettingSlice';
import { createShippingSlice } from './createShippingSlice';

const useCheckoutStore = create<
  CartSlice &
    AuthSlice &
    SettingSlice &
    CurrencySlice &
    AddressSlice &
    PaymentSlice
>()(
  devtools(
    (...a) => ({
      ...createAuthSlice(...a),
      ...createCartSlice(...a),
      ...createSettingSlice(...a),
      ...createCurrencySlice(...a),
      ...createShippingSlice(...a),
      ...createPaymentSlice(...a),
    }),
    {
      store: 'checkoutStore',
      trace: true,
    }
  )
);

export default useCheckoutStore;
