const en = {
  signin: 'Sign in',
  customer: 'Customer',
  shipping: 'Shipping',
  payment: 'Payment',
  copyright: 'All rights reserved',
  recoverYourPassword: 'Recover your password',
  resetYourPassword: 'Reset your password',
  recoverPassword: 'Recover Password',
  resetPassword: 'Reset Password',
  orderSummary: 'Order Summary',
  resetSuccess: 'A recover password email has been sent to you.',
  resetPasswordSuccess:
    'New password has successfully been set. You will be redirected to login page.',
  resetPasswordFailed:
    'Double check your password or your key has expired. Request a new password reset.',
  resetFailed: 'Failed to send reset email. Try again !!',
  alreadyhaveIt: 'Already have it?',
  requestAgain: 'Want to request again?',
  login: 'Log in',
  forgotYourPassword: 'Forgot your password?',
  signInToYourAccount: 'Sign into your account',
  customerInfo: 'Customer Information',
  signOut: 'Sign out',
  continueAs: 'Continue As',
  guest: 'Guest',
  haveAnAccount: 'Already have an account?',
  keepMeUpdated: 'Keep me up to date with news and special offers',
  invalidCredentials: 'Invalid Login credentials',
  shippingAddress: 'Shipping Address',
  contact: 'Contact',
  change: 'Change',
  shippingDatesAndDetails: 'Select Shipping details & dates',
  deliveryOutOf: (current: number, total: number) =>
    `Delivery ${current} of ${total}`,
  method: 'Method',
  paymentMethods: 'Payment Methods',
  splitMessage: (count: number) =>
    `Please note your order has been split into ${count} deliveries`,
  secureAndEncrypted: 'All transactions are secure and encrypted.',
  card: 'Credit card',
  addCardToVault: 'Add Card to vault',
  deliveryInstructions: 'Provide delivery instructions (optional)',
  invalidLogin: 'Your email or password is incorrect, please try again.',
  defaultMarkdown: `
[Click here to pay with Wise](https://wise.com/pay/business/ridgelandassociateslimited)

The currency you check out with should match the currency you select in the link above.

If your currency is not available, please go back to the storefront and change the currency of your order to USD or GBP and then pay in that currency.

All fees associated with the transaction must be paid by you.
`,
};

const strings = en;
export default strings;
