// @ts-nocheck
/* eslint-disable react-hooks/rules-of-hooks */

import getConfig from 'next/config';
import swell from 'swell-js';
import type { StateCreator } from 'zustand';

import type { CustomerInfoValues } from '@/components/CustomerInformation/customerInformationTypes';
import { matchAddress } from '@/utils/commonFunctions';
import { postData } from '@/utils/request';

import type { AddressSlice } from './checkoutStoreTypes';
import type {
  BlockedDeliveryService,
  DeliveryService,
  ServiceProps,
  SwellApiResponse,
  SwellShippingProps,
} from './checkoutTypes';
// eslint-disable-next-line import/no-cycle
import { createCartSlice } from './createCartSlice';
import { createSettingSlice } from './createSettingSlice';

export const createShippingSlice: StateCreator<AddressSlice> = (set, get) => ({
  addressList: { count: 0, page: 1, results: [] },
  shippingRates: null,
  deliveryService: null,
  tempShipping: [],

  /**
   * this function create address
   * @param {CustomerInfoValues} values
   * @returns {Promise<{success: boolean, message: string}>}
   */
  createAddress: async (values: CustomerInfoValues) => {
    createSettingSlice(set).setBlur(true);
    try {
      const {
        address1,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        first_name,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        last_name,
        address2,
        city,
        state,
        zip,
        country,
        phone,
      } = values;
      const address = (await swell.account.createAddress({
        name: `${first_name} ${last_name}`,
        address1,
        address2,
        city,
        state,
        zip,
        country,
        phone,
      })) as SwellApiResponse;
      if (address.name) {
        createSettingSlice(set).setBlur(false);
        get().getAddressList();
        return { success: true, message: 'Address Created' };
      }
      createSettingSlice(set).setBlur(false);
      return {
        success: false,
        errors: address.errors,
        message: 'Address creation Failed',
      };
    } catch (error: any) {
      createSettingSlice(set).setBlur(false);
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
      return { success: false, message: error.message };
    }
  },

  /**
   * this function update address
   * @param {string} id
   * @param {any} address
   */
  updateAddress: async (id: string, address: any) => {
    try {
      const res = await swell.account.updateAddress(id, address);
      if (res?.phone) {
        createSettingSlice(set).setBlur(false);
        get().getAddressList();
        return { success: true, message: 'Address Created' };
      }
      return {
        success: false,
        errors: address.errors,
        message: 'Address creation Failed',
      };
    } catch (error: any) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
      return {
        success: false,
        errors: {},
        message: error.message,
      };
    }
  },

  /**
   * this function get the address list from swll server
   * @returns {Promise<void>}
   */
  getAddressList: async () => {
    try {
      const res = (await swell.account.listAddresses()) as any;
      set((prevState) => ({
        ...prevState,
        addressList: res,
      }));
    } catch (error) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
    }
  },

  /**
   * this function get the shipping rates from swell
   * @returns {Promise<void>}
   */
  getShippingRates: async () => {
    try {
      const cart = await swell.cart.getShippingRates();
      get().setShipmentRatingFromCart(cart);
    } catch (error) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
    }
  },

  /**
   * this function set the shipping address in the cart
   * @param {SwellShippingProps} shipping
   * @param {any} addressList
   * @param {boolean} has_tax_number
   * @param {string} tax_number
   */
  setCheckoutShipping: async (
    shipping: SwellShippingProps,
    addressList: any,
    has_tax_number?: boolean,
    tax_number?: string
  ) => {
    try {
      createSettingSlice(set).setBlur(true);
      const matchedAddress = addressList.results.find((address: any) =>
        matchAddress(shipping, address)
      )?.id;
      const cart = await swell.cart.update({
        shipping,
        metadata: {
          step: 2,
          shipAddress: matchedAddress || '',
          tax_number,
          has_tax_number,
        },
      });
      if (!cart) throw new Error('Cart Update Failed');
      get().setShipmentRatingFromCart(cart);
      set((prevState) => ({
        ...prevState,
        cart,
        isBlur: false,
        isLoading: false,
        error: '',
      }));
      set((prevState) => ({
        ...prevState,
        step: 2,
        isBlur: false,
      }));

      createSettingSlice(set).setBlur(false);
      return {
        success: true,
      };
    } catch (error: any) {
      createSettingSlice(set).setBlur(false);
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  /**
   * this function update the delivery info
   * @param {ServiceProps[]} services
   * @param {string} instructions
   * @param {string} cartId
   */
  updateDeliveryInfo: async (
    services: ServiceProps[],
    instructions: string,
    cartId: string
  ) => {
    try {
      createSettingSlice(set).setBlur(true);
      const selectedServices = services.map(
        (service) => service.carrierServiceId
      );
      const res = await postData(`/api/assign-shipping-price`, {
        cartId,
        services: selectedServices,
        instructions,
      });
      if (res.status === false) throw new Error(res.message);
      await createCartSlice(set).fetchCart();
      set((prevState) => ({
        ...prevState,
        step: 3,
        isBlur: false,
      }));
      createSettingSlice(set).setBlur(false);
      return {
        success: true,
      };
    } catch (error: any) {
      createSettingSlice(set).setBlur(false);
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  /** function is responsible for updating the state with the shipment rating information obtained from the cart.
   * @param {any} cart
   * @returns {void}
   */
  setShipmentRatingFromCart: (cart: any) => {
    try {
      if (cart?.shipmentRating?.services) {
        const blockedCheckout = cart.shipmentRating.services.find(
          (el: any) => el.id.toLowerCase() === 'blocked'
        ) as BlockedDeliveryService;
        const deliveryService = cart.shipmentRating.services.find(
          (el: any) => el.id.toLowerCase() === 'deliveries'
        ) as DeliveryService;
        const standardShipping = cart.shipmentRating.services.find(
          (el: any) =>
            el.id.toLowerCase() ===
            getConfig().publicRuntimeConfig.standardShippingId
        ) as DeliveryService;

        if (typeof blockedCheckout !== 'undefined') {
          set((prevState) => ({
            ...prevState,
            isCheckoutBlocked: true,
            blockedCheckout,
          }));
        } else if (typeof standardShipping !== 'undefined') {
          set((prevState) => ({
            ...prevState,
            deliveryService: {
              ...standardShipping,
              isStandardShipping: true,
            },
            isCheckoutBlocked: false,
            blockedCheckout: undefined,
            isBlur: false,
          }));
        } else if (typeof deliveryService !== 'undefined') {
          set((prevState) => ({
            ...prevState,
            deliveryService,
            isCheckoutBlocked: false,
            blockedCheckout: undefined,
          }));
        }
      }

      set((prevState) => ({
        ...prevState,
        shippingRates: cart?.shipmentRating,
        blockedCheckout: undefined,
        isBlur: false,
      }));
    } catch (error: any) {
      throw new Error(error.message);
    }
  },

  /**
   * this function fetch the shipping rates.
   * @returns {Promise<void>}
   */
  fetchShippingRates: async () => {
    const { cart } = get();
    if (
      cart?.shipmentRating === null &&
      cart?.shipping.country &&
      cart?.shipping.zip &&
      cart?.shipping.state
    ) {
      await get().getShippingRates();
    }
  },
});
