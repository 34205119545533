import type { AppProps } from 'next/app';
import Router from 'next/router';
import { useEffect, useState } from 'react';

import Loader from './loading';

const RouterLoading = ({ Component, pageProps }: AppProps) => {
  const [routing, setRouting] = useState(false);
  useEffect(() => {
    Router.events.on('routeChangeStart', () => {
      setRouting(true);
    });

    Router.events.on('routeChangeComplete', () => {
      setRouting(false);
    });
  }, []);

  return routing ? (
    <div className="fixed top-0 left-0 z-10 flex h-screen w-screen flex-col items-center justify-center gap-5 bg-white text-primary">
      <Loader size={40} />
      <p>Please wait while we are loading</p>
    </div>
  ) : (
    <Component {...pageProps} />
  );
};

export default RouterLoading;
