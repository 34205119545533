// @ts-nocheck
import swell from 'swell-js';
import type { StateCreator } from 'zustand';

import type { PaymentSlice } from './checkoutStoreTypes';
import { createCartSlice } from './createCartSlice';
import { createSettingSlice } from './createSettingSlice';

export const createPaymentSlice: StateCreator<PaymentSlice> = (set, get) => ({
  cardsList: [],
  checkoutId: null,
  bankData: [],

  /**
   * this function set the bank data in the state
   * @param {Array} bankData
   * @returns {void}
   */
  setBankData: (bankData) => {
    set((state) => ({ ...state, bankData: [...bankData] }));
  },

  /**
   * this function set the checkout id in the state
   * @param {string} id
   * @returns {void}
   */
  setCheckoutId: async (id: string) => {
    set((prevState) => ({
      ...prevState,
      checkoutId: id,
    }));
  },

  /**
   * this function get the list of cards
   * @returns {Promise<void>}
   */
  getCards: async () => {
    try {
      const { customer } = get();
      if (customer) {
        const response: any = await swell.account.listCards();
        set((prevState) => ({
          ...prevState,
          cardsList: response.results,
        }));
      }
    } catch (error) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
    }
  },

  /**
   * this function add card in the vault
   * @param {string} token
   * @returns {Promise<{ success: boolean, message: string }>}
   */
  addCardToVault: async (token: string) => {
    try {
      const card = await swell.account.createCard({
        token,
      });
      if (card) {
        return {
          success: true,
        };
      }
      return {
        success: false,
      };
    } catch (error: any) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  /** function is responsible for creating a token for a payment card using the method.
   * @returns {Promise<{ success: boolean, id: string }>}
   */
  tokenize: () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await swell.payment.tokenize({
          card: {
            onError: (err: any) => {
              // eslint-disable-next-line prefer-promise-reject-errors
              reject({
                success: false,
                message: err.message,
              });
            },
            onSuccess: async (res: any) => {
              await createCartSlice(set).fetchCart();
              resolve({
                success: true,
                id: res?.billing.card.token,
              });
            },
          },
        });
      } catch (error) {
        reject(error);
      }
    });
  },

  /**
   * this function get the selected card id. this function used for invoice checkout
   * @param {string} token
   * @returns {string}
   */
  getSelectedCard: (token: string) => {
    const selectedCard = get().cardsList.find(
      (card: any) => card.token === token
    );
    return selectedCard?.id;
  },
});
