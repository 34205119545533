/* eslint-disable no-nested-ternary */
import TitleCase from './titleCase';

export const createShippingAddressObject = (values: any, checked?: string) => {
  const name =
    !checked || checked === '0'
      ? `${values.first_name} ${values.last_name}`
      : `${values.firstName} ${values.lastName}`;
  const firstName =
    !checked || checked === '0' ? values.first_name : values.firstName;
  const lastName =
    !checked || checked === '0' ? values.last_name : values.lastName;
  return {
    name,
    first_name: firstName,
    last_name: lastName,
    address1: values.address1,
    address2: values.address2,
    city: values.city,
    state: values.state,
    zip: values.zip,
    country: values.country,
    phone: values.phone,
  };
};

export const printAddress = (cart: any, type: string = 'shipping') => {
  const address = type === 'shipping' ? cart?.shipping : cart?.billing;
  return address
    ? `${TitleCase(address?.name) || ''}, ${address?.address1 || ''} ${
        address?.address2 || ''
      } ${address?.city || ''} ${address?.state || ''} ${address?.zip || ''} (${
        address?.country || ''
      })`
    : '';
};

export const navigateWithStep = (
  cart: any,
  checkoutId: string,
  Router: any
) => {
  if (cart?.metadata?.step && cart.metadata.step === 3)
    Router.push(`/${checkoutId}/payment`);
  else if (cart?.metadata?.step && cart.metadata.step === 2)
    Router.push(`/${checkoutId}/shipping`);
  else Router.push(`/${checkoutId}/`);
};

export const getShippingTotal = (cart: any, deliveries: any) => {
  if (cart?.shipping?.price) return cart.shipping.price;
  if (deliveries && deliveries.length > 0)
    return deliveries[0]?.deliveries.reduce(
      (total: any, shipping: any) =>
        total.services[total.selectedService].price +
        shipping.services[shipping.selectedService].price
    );
  return '-';
};

export const getGrandTotal = (total: number, cart: any) => {
  const hasGiftCards = cart?.giftcards?.length > 0;
  if (!hasGiftCards) return total;
  if (cart?.giftcardTotal >= total) return 0;
  return total - (cart?.giftcardTotal || 0);
};

export const matchAddress = (address1: any, address2: any) => {
  if (address1 && address2) {
    const nameCheck =
      address1?.name && address2?.name ? address1.name === address2.name : true;
    const firstNameCheck =
      (address1?.firstName || address1.first_name) ===
      (address2?.firstName || address2.first_name);
    const lastNameCheck =
      (address1?.lastName || address1.last_name) ===
      (address2?.lastName || address2.last_name);
    const address1Check = address1.address1 === address2.address1;
    const address2Check = address1.address2 === address2.address2;
    const cityCheck = address1.city === address2.city;
    const stateCheck = address1.state === address2.state;
    const zipCheck = address1.zip === address2.zip;
    const countryCheck = address1.country === address2.country;
    const phoneCheck = address1.phone === address2.phone;
    return (
      nameCheck &&
      firstNameCheck &&
      lastNameCheck &&
      address1Check &&
      address2Check &&
      cityCheck &&
      stateCheck &&
      zipCheck &&
      countryCheck &&
      phoneCheck
    );
  }
  return false;
};
