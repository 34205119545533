import { useRouter } from 'next/router';
import { useEffect } from 'react';
import swell from 'swell-js';

import useCheckoutStore from './checkoutStore';

const options = {
  useCamelCase: true,
};

swell.init(
  process.env.NEXT_SWELL_PUBLIC_STORE as string,
  process.env.NEXT_SWELL_PUBLIC_API_TOKEN as string,
  options
);
const ZustandLoad = () => {
  const router = useRouter();
  const {
    settings,
    isBlur,
    isLoading,
    customer,
    setError,
    fetchCart,
    getSettings,
    activatePaymentMethod,
    getCheckoutSettings,
    checkUser,
    setCheckoutId,
    checkoutId,
    initialLoad,
    completeInitialLoad,
    setLoading,
    filterPayments,
    activatePaymentMethodForInvoice,
  } = useCheckoutStore();

  // This ensures that user is not able to go other pages without logging in
  useEffect(() => {
    const pageName = router.route.split('/')[2] ?? 'information';
    if (
      // is not initial load
      initialLoad !== true &&
      settings !== null &&
      // does not have customer
      !customer?.email &&
      !isLoading &&
      !isBlur &&
      (pageName === 'payment' || pageName === 'shipping')
    ) {
      setError(new Error('You must be logged in to continue'));
      router.push(`/${checkoutId}/login`);
    }
  }, [customer, isLoading, isBlur, settings, router.route, initialLoad]);

  const initiateLoad = async (id: string) => {
    await Promise.all([
      checkUser(),
      getSettings(),
      getCheckoutSettings(),
      fetchCart(id as string),
    ]);
    const path = router.asPath;
    switch (true) {
      case path.includes('invoice'):
        filterPayments({
          manual: false,
        });
        activatePaymentMethodForInvoice();
        break;
      default:
        activatePaymentMethod();
        break;
    }
    completeInitialLoad();
    setLoading(false);
    // App load is finished
  };
  useEffect(() => {
    if (!router.isReady) return;
    setLoading(true);
    const routerCheckoutId = router.query.slug;
    if (checkoutId === null && typeof routerCheckoutId !== 'undefined') {
      setCheckoutId(routerCheckoutId as string);
    }
    // this happens when you land on the page without the slug.
    if (typeof routerCheckoutId !== 'undefined') {
      const id = routerCheckoutId ?? checkoutId;
      initiateLoad(id as string);
    }
  }, [router.isReady]);
  return null;
};

export default ZustandLoad;
