/* eslint-disable react-hooks/rules-of-hooks */
// @ts-nocheck

import Bugsnag from '@bugsnag/js';
import swell from 'swell-js';
import type { StateCreator } from 'zustand';

import type { CustomerInfoValues } from '@/components/CustomerInformation/customerInformationTypes';
import strings from '@/utils/string';

import type { AuthSlice } from './checkoutStoreTypes';
import type { SwellApiResponse, SwellLoginProps } from './checkoutTypes';
import { createSettingSlice } from './createSettingSlice';
import { createShippingSlice } from './createShippingSlice';

export const createAuthSlice: StateCreator<AuthSlice> = (set) => ({
  /** User Login
   * @param {string} email
   * @param {string} password
   * @returns {Promise<{ success: boolean, customer: SwellApiResponse }>}
   */
  login: async ({ email, password }: SwellLoginProps) => {
    createSettingSlice(set).setBlur(true);
    try {
      const customer = await swell.account.login(email, password);
      if (customer) {
        set((prevState) => ({
          ...prevState,
          customer,
          error: '',
        }));
        createShippingSlice(set).getAddressList();
        createSettingSlice(set).setBlur(false);
        Bugsnag.setUser(customer?.id);
        return { success: true, customer };
      }
      throw new Error(strings.invalidLogin);
    } catch (error: any) {
      createSettingSlice(set).setBlur(false);

      return {
        success: false,
        message: error?.message ?? strings.invalidLogin,
      };
    }
  },

  /** this function recover password
   * @param {string} email
   * @param {string} slug
   * @returns {Promise<{ success: boolean, error: any }>}
   */
  recoverPassword: async (email: string, slug: string) => {
    createSettingSlice(set).setBlur(true);
    try {
      if (
        typeof process.env.NEXT_CHECKOUT_URL === 'undefined' ||
        process.env.NEXT_CHECKOUT_URL === ''
      )
        throw new Error('Checkout URL must be defined. Contact developer.');
      const res = await swell.account.recover({
        email,
        reset_url: `${process.env.NEXT_CHECKOUT_URL}/${slug}/recover-password?key={reset_key}`,
      });
      createSettingSlice(set).setBlur(false);
      return res as { success: boolean };
    } catch (error) {
      createSettingSlice(set).setBlur(false);
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
      return { success: false, error };
    }
  },

  /**  checking if a user is logged in.
   * @returns {Promise<void>}
   */
  checkUser: async () => {
    try {
      const customer = await swell.account.get();
      if (customer) {
        set((prevState) => ({
          ...prevState,
          customer,
        }));
        createShippingSlice(set).getAddressList();
      }
      return await Promise.resolve();
    } catch (error) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
      return await Promise.reject();
    }
  },

  /** this function will logout the user
   * @returns {Promise<void>}
   */
  logout: async () => {
    createSettingSlice(set).setBlur(true);
    try {
      await swell.account.logout();
      set((prevState) => ({
        ...prevState,
        customer: null,
        addressList: { count: 0, page: 1, results: [] },
      }));
      createSettingSlice(set).setBlur(false);
    } catch (error) {
      createSettingSlice(set).setBlur(false);
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
    }
  },

  /** this fuctions reset the user password
   * @param {string} password
   * @param {string} confirmPassword
   * @param {string} resetKey
   * @returns {Promise<{ success: boolean, error: any }>}
   */
  resetPassword: async (
    password: string,
    confirmPassword: string,
    resetKey: string
  ) => {
    createSettingSlice(set).setBlur(true);
    try {
      if (password !== confirmPassword)
        throw new Error('The password did not match.');
      if (!resetKey) throw new Error('Reset key is required');
      const res = await swell.account.recover({
        password: confirmPassword,
        reset_key: resetKey,
      });
      createSettingSlice(set).setBlur(false);
      return res as { success: boolean };
    } catch (error) {
      createSettingSlice(set).setBlur(false);
      createSettingSlice(set).setError(error);
      if (
        error?.message !== 'Reset key is required' ||
        error?.message !== 'The password did not match.'
      )
        createSettingSlice(set).reportError(error);
      return { success: false };
    }
  },

  /** This function is responsible for creating a new user account.
   * @param {CustomerInfoValues} values
   * @returns {Promise<{ success: boolean, message: string }>}
   */
  createUser: async (values: CustomerInfoValues) => {
    createSettingSlice(set).setBlur(true);
    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { email, first_name, last_name, password } = values;
      const account = (await swell.account.create({
        email,
        first_name,
        last_name,
        email_optin: true,
        password,
      })) as SwellApiResponse;
      if (account?.email === values.email) {
        set((prevState) => ({
          ...prevState,
          customer: account,
          isBlur: false,
        }));
        return { success: true, message: 'Account Created' };
      }
      if (typeof account?.errors?.email !== 'undefined')
        throw new Error(
          'This email address is already registered. Please log in to continue.'
        );
      createSettingSlice(set).setBlur(false);
      return account?.email || 'Create account failed';
    } catch (error) {
      createSettingSlice(set).setBlur(false);
      createSettingSlice(set).setError(error);
      if (
        error?.message !==
        'This email address is already registered. Please log in to continue.'
      )
        createSettingSlice(set).reportError(error);
      return { success: false, message: error };
    }
  },

  /** This functio verify phone number
   * @param {string} code
   * @returns {void}
   */
  setVerified: async (phone: string) => {
    try {
      const numbers = [];
      numbers.push(phone);
      const res = await swell.account.update({
        metadata: {
          verifiedPhone: numbers,
        },
      });
      if (res) {
        set((prevState) => ({
          ...prevState,
          customer: { ...res },
          isBlur: false,
          error: '',
        }));
      }
    } catch (error) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
    }
  },

  /**
   * this function will update the Data
   * @param {any} entry
   */
  setData: async (entry: any) => {
    set((prevState) => ({
      ...prevState,
      data: { ...prevState.data, ...entry },
    }));
  },
});
