import Bugsnag from '@bugsnag/js';
import swell from 'swell-js';
import type { StateCreator } from 'zustand';

import { getData } from '@/utils/request';

import type { SettingSlice } from './checkoutStoreTypes';
import type { CheckoutSettingsResponse } from './checkoutTypes';

export const createSettingSlice: StateCreator<SettingSlice> = (set, get) => ({
  settings: null,
  step: 1,
  isBlur: false,
  isLoading: true,
  error: null,
  isCheckoutBlocked: false,
  blockedCheckout: undefined,
  checkoutSettings: null,
  loadingMessage: null,
  initialLoad: true,

  /** this function set the Error and notify on bugsnag
   * @param {any} error
   * @returns {void}
   */
  setError: (error: any) => {
    set((prevState: any) => ({ ...prevState, error: error.message || error }));
  },

  /** this function set the Error and notify on bugsnag
   * @param {any} error
   * @returns {void}
   */
  reportError: (error: any) => {
    Bugsnag.notify(error);
  },

  /** this function assugn flag when initial load complete
   * @returns {void}
   */
  completeInitialLoad: () => {
    set((prevState) => ({ ...prevState, initialLoad: false }));
  },

  /**
   * this function assign isLoading  flag in store
   * @param {boolean} isLoading
   * @returns {void}
   */
  setLoading: (isLoading: boolean) => {
    set((prevState: any) => ({
      ...prevState,
      isLoading,
    }));
  },

  /**
   * this fuction set Message in store
   * @param {string} message
   */
  setLoadingMessage: (message: string) => {
    set((prevState: any) => ({
      ...prevState,
      loadingMessage: message,
    }));
  },

  /**
   * this fuction set Message with delay in store
   * @param {string} message
   * @param {number} delay
   * @returns {void}
   */
  setLoadingMessageWithDelay: (message: string, delay: number = 1000) => {
    setTimeout(() => {
      get().setLoadingMessage(message);
    }, delay);
  },

  /**
   * this function set the blur in the store
   * @param isBlur {boolean}
   */
  setBlur: (isBlur: boolean) => {
    set((prevState: any) => ({
      ...prevState,
      isBlur,
    }));
  },

  /** function is responsible for fetching the checkout settings from the backend server.
   * @returns {Promise<void>}
   */
  getCheckoutSettings: async () => {
    try {
      if (
        typeof process.env.NEXT_CHECKOUT_SETTINGS_ROUTE === 'undefined' ||
        typeof process.env.NEXT_CHECKOUT_SETTINGS_PUBLIC_KEY === 'undefined'
      ) {
        throw new Error('Settiings route is required');
      }

      const res: CheckoutSettingsResponse = await getData(
        process.env.NEXT_CHECKOUT_SETTINGS_ROUTE,
        {
          Authorization: process.env.NEXT_CHECKOUT_SETTINGS_PUBLIC_KEY,
        }
      );

      if (!res?.status || res?.error) {
        throw new Error('Something went wrong with backend server request');
      } else {
        set((prevState: any) => ({
          ...prevState,
          checkoutSettings: res.data,
        }));
      }
      return await Promise.resolve();
    } catch (error: any) {
      get().setError(
        error?.message ??
          'Something wrong with data/checkout endpoint on backend app'
      );
      get().setLoading(false);
      get().reportError(error); // Make sure Bugsnag is properly configured
      return await Promise.reject();
    }
  },

  /**
   * this function get the settings from swell
   * @returns {Promise<void>}
   */
  getSettings: async () => {
    try {
      const res = (await swell.cart.getSettings()) as any;
      if (res) {
        set((prevState: any) => ({ ...prevState, settings: res }));
      }
    } catch (error) {
      get().setError(error);
      get().reportError(error);
    }
  },

  /**
   * this function set the step in the store
   * @param {number} val
   * @param {any} cart
   * @returns {Promise<void>}
   */
  setStep: async (val: number, cart: any) => {
    get().setBlur(true);
    try {
      await swell.cart.update({
        metadata: {
          ...cart.metadata,
          step: val,
        },
      });
      set((prevState: any) => ({
        ...prevState,
        step: val,
        isBlur: false,
      }));
    } catch (error) {
      get().setError(error);
      get().reportError(error);
    }
    get().setBlur(false);
  },

  /**
   * this function activate the payment method
   * @returns {Promise<void>}
   */
  activatePaymentMethod: () => {
    try {
      const currentSettings = get().settings;
      const inactiveMethods = ['giftcard', 'account'];
      // @ts-ignore
      const methods = currentSettings?.paymentMethods.map((item: any) => {
        return {
          ...item,
          active: !inactiveMethods.includes(item.id),
        };
      });
      set((prevState: any) => ({
        ...prevState,
        settings: {
          ...currentSettings,
          paymentMethods: methods,
        },
      }));
    } catch (error) {
      get().setError('Something went wrong while activating payment methods');
      get().reportError(error);
    }
  },

  /**
   * this function filter the payment method
   * @param {any} obj
   * @returns {void}
   */
  filterPayments: (obj: any) => {
    const { manual } = obj;
    const { settings } = get();
    // @ts-ignore
    const manualPayment = settings?.paymentMethods?.map((item: any) => ({
      ...item,
      active: manual ? item.manual : Boolean(!item.manual),
    }));

    set((prevState: any) => ({
      ...prevState,
      settings: {
        ...prevState.settings,
        paymentMethods: manualPayment,
      },
    }));
  },

  /**
   * this function generate unique id
   * @returns {string}
   * @returns {string}
   */
  generateUniqueId: () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 10000); // Adjust the range as needed
    return `${timestamp}-${random}`;
  },

  /**
   * this function check if the payment method exist
   * @returns {boolean}
   */
  hasActivePaymentMethod: () => {
    const { settings } = get();
    return (
      // @ts-ignore
      settings?.paymentMethods?.filter((item: any) => item.active).length > 0
    );
  },

  /**
   * this function activate the payment method for invoice
   * @returns {Promise<void>}
   */
  activatePaymentMethodForInvoice: () => {
    try {
      const currentSettings = get().settings;
      const inactiveMethods = [
        'giftcard',
        'account',
        'paypal',
        'transferwise',
        'cash',
      ];
      // @ts-ignore
      const methods = currentSettings?.paymentMethods.map((item: any) => {
        return {
          ...item,
          active: !inactiveMethods.includes(item.id),
        };
      });
      set((prevState: any) => ({
        ...prevState,
        settings: {
          ...currentSettings,
          paymentMethods: methods,
        },
      }));
    } catch (error) {
      get().setError('Something went wrong while activating payments');
      get().reportError(error);
    }
  },
});
