import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

const getCartIdFromUrl = () => {
  if (typeof window === 'undefined') return null;
  const match = window.location.pathname.match(/\/([a-f0-9]{32})\//);
  return match ? match[1] : null;
};

const getCurrentPage = () => {
  if (typeof window === 'undefined') return null;
  const path = window.location.pathname;
  const cartId = getCartIdFromUrl();
  if (cartId) {
    const remainingPath = path.replace(`/${cartId}`, '');
    return remainingPath || '/';
  }
  return path;
};

Bugsnag.start({
  apiKey: process.env.NEXT_BUGSNAG_API_KEY as string,
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.NEXT_CHECKOUT_BUGSNAG_ENV,
  autoTrackSessions: true,
  metadata: {
    domain: {
      hostname:
        typeof window !== 'undefined' ? window.location.hostname : undefined,
      store: process.env.NEXT_SWELL_PUBLIC_STORE,
    },
    checkout: {
      cartId: getCartIdFromUrl(),
      currentPage: getCurrentPage(),
    },
    vercel: {
      environment: process.env.NEXT_CHECKOUT_BUGSNAG_ENV,
      url: process.env.VERCEL_URL,
      region: process.env.VERCEL_REGION,
      deploymentId: process.env.VERCEL_DEPLOYMENT_ID,
      gitProvider: process.env.VERCEL_GIT_PROVIDER,
      gitRepoSlug: process.env.VERCEL_GIT_REPO_SLUG,
      gitRepoOwner: process.env.VERCEL_GIT_REPO_OWNER,
      gitCommitSha: process.env.VERCEL_GIT_COMMIT_SHA,
      gitCommitMessage: process.env.VERCEL_GIT_COMMIT_MESSAGE,
      gitCommitAuthor: process.env.VERCEL_GIT_COMMIT_AUTHOR_NAME,
    },
  },
});
// @ts-ignore
const BugSnagBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

export default BugSnagBoundary;
