// @ts-nocheck
/* eslint-disable react-hooks/rules-of-hooks */

import swell from 'swell-js';
import type { StateCreator } from 'zustand';

import { getGrandTotal } from '@/utils/commonFunctions';
import { postData } from '@/utils/request';

import type { CartSlice } from './checkoutStoreTypes';
// eslint-disable-next-line import/no-cycle
import { createCurrencySlice } from './createCurrencySlice';
import { createSettingSlice } from './createSettingSlice';
// eslint-disable-next-line import/no-cycle
import { createShippingSlice } from './createShippingSlice';

export const createCartSlice: StateCreator<CartSlice> = (set, get) => ({
  submitError: null,
  cart: null,

  /** This function is checking if the current cart contains a gift card
   * @returns {boolean}
   */
  isGiftCard: (): boolean => {
    return Boolean(
      get().cart?.giftcardTotal &&
        get().cart.grandTotal - (get().cart?.giftcardTotal || 0) <= 0
    );
  },

  /** Checks if the order is free
   * @returns {boolean}
   */
  isFreeOrder: (): boolean => {
    // !NOTE: If cart isn't loaded, it's not free at all.
    try {
      return (
        get().isGiftCard() ||
        // is covered by account credit
        (get().cart.grandTotal <= get().cart.account.balance &&
          get().cart.accountCreditApplied) ||
        // is not paid order
        !(
          get().cart.grandTotal > 0 &&
          getGrandTotal(get().cart.grandTotal, get().cart) > 0
        )
      );
    } catch (error) {
      return false;
    }
  },

  /** The `setSubmitError` function is used to update the `submitError` property in the state.
   * @param {string} message
   * @returns {void}
   */
  setSubmitError: (message: string) => {
    set((prevState: any) => ({ ...prevState, submitError: message }));
  },

  /** function that is used to submit an order.
   * @returns {Promise<{ success: boolean, message: string }>}
   * @throws {Error}
   */
  submitOrder: async () => {
    createSettingSlice(set).setLoading(true);
    try {
      createSettingSlice(set).setLoadingMessage('Checking order details...');
      const order = await swell.cart.submitOrder();
      createSettingSlice(set).setLoadingMessage('Submitting order...');
      if (!order) throw new Error('Order not submitted');
      createSettingSlice(set).setLoadingMessage('Order submitted...');
      set((prevState: any) => ({
        ...prevState,
        order,
        isBlur: false,
        error: '',
        step: 4,
      }));
      createSettingSlice(set).setLoadingMessage('Proceeding to next step...');
      // Donot turn off loading after order is submitted. Turn off loading after handler is successfully executed.
      return {
        success: true,
      };
    } catch (error: any) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
      createSettingSlice(set).setLoading(false);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  /**  Apply coupon in the cart
   * @param {string} coupon
   * @returns {Promise<void>}
   */
  applyCoupon: async (coupon: string) => {
    createSettingSlice(set).setBlur(true);
    try {
      const res: any = await swell.cart.applyCoupon(coupon);
      set((prevState) => ({
        ...prevState,
        cart: { ...res },
        coupon,
        error: '',
      }));
      createSettingSlice(set).setBlur(false);
    } catch (error) {
      createSettingSlice(set).setBlur(false);
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
    }
  },

  /**  remove coupon in the cart
   * @returns {Promise<void>}
   */
  removeCoupon: async () => {
    createSettingSlice(set).setBlur(true);
    try {
      // @ts-ignore
      const res: any = await swell.cart.removeCoupon();
      set((prevState) => ({
        ...prevState,
        cart: { ...res },
        coupon: null,
        error: '',
      }));
      createSettingSlice(set).setBlur(false);
    } catch (error) {
      createSettingSlice(set).setBlur(false);
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
    }
  },

  /** add gift card in the cart
   *  @param {string} id
   */
  addGiftCard: async (id: string) => {
    try {
      createSettingSlice(set).setBlur(true);
      // @ts-ignore
      const res = await swell.cart.applyGiftcard(id);
      if (!res)
        throw new Error('Your gift card code was not found or no longer valid');
      else if (res.giftcards.at(0).amount === 0) {
        get().removeGiftCard(res?.giftcards?.at(0)?.id);
        throw new Error('Gift card completely used');
      } else {
        set((prevState) => ({
          ...prevState,
          cart: { ...res },
          error: '',
          submitError: '',
        }));
      }
    } catch (error) {
      get().setSubmitError(error?.message);
    } finally {
      createSettingSlice(set).setBlur(false);
    }
  },

  /** remove gift card in the cart
   *  @param {string} id
   */
  removeGiftCard: async (id: string) => {
    createSettingSlice(set).setBlur(true);
    try {
      // @ts-ignore
      const res = await swell.cart.removeGiftcard(id);
      if (res.error) throw new Error(res.error.message);
      set((prevState) => ({
        ...prevState,
        cart: { ...res },
        error: '',
      }));
    } catch (error) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
    }
    createSettingSlice(set).setBlur(false);
  },

  /** this function that is used to update the cart
   * @param {any} data
   * @returns {Promise<{ success: boolean, message: string }>}
   */
  updateCart: async (data: any) => {
    try {
      createSettingSlice(set).setBlur(true);
      createSettingSlice(set).setLoadingMessage('Updating cart...');
      const cart = await swell.cart.update({
        ...data,
      });
      if (!cart) throw new Error('cart update failed');
      createSettingSlice(set).setLoadingMessage('Cart updated...');
      set((prevState) => ({
        ...prevState,
        cart,
        isBlur: false,
        error: '',
      }));
      return {
        success: true,
      };
    } catch (error: any) {
      createSettingSlice(set).setError(error);
      createSettingSlice(set).reportError(error);
      createSettingSlice(set).setBlur(false);
      return {
        success: false,
        message: error.message,
      };
    }
  },

  /** This function update the tempShipping value
   * @param {Array<any>} value
   * @returns {void}
   */
  updateTempShipping: (value: Array<any>) => {
    set((prevState) => ({
      ...prevState,
      tempShipping: value,
    }));
  },

  /** This function fetch cart or order from backend
   * @param {string} id
   * @returns {Promise<any>}
   */
  fetchCart: async (id?: string) => {
    try {
      let cart = await swell.cart.get();
      let order: any;
      if (!id && !cart) {
        throw new Error('Cart not found as checkout id not available.');
      }
      if (id && (!cart || (cart?.checkoutId && cart?.checkoutId !== id))) {
        cart = id ? await swell.cart.recover(id) : null;
        if (!cart && id) {
          createSettingSlice(set).setLoadingMessage('Looking for an order...');
          // @ts-ignore
          order = await swell.cart.getOrder(id);
          if (!order || order === null) {
            throw new Error('Neither cart nor order found');
          }
          createSettingSlice(set).setLoadingMessage('Found for an order...');
          createShippingSlice(set).setShipmentRatingFromCart(order);
          set((prevState) => ({
            ...prevState,
            order,
            step: 4,
            error: '',
          }));

          createCurrencySlice(set).getCurrencies(order?.currency);
          return await Promise.resolve(order);
        }
      }
      createShippingSlice(set).setShipmentRatingFromCart(cart);
      // @ts-ignore
      if (cart && cart?.metadata && cart?.metadata?.step) {
        // @ts-ignore
        set((prevState) => ({
          ...prevState,
          // @ts-ignore
          step: cart?.metadata?.step,
        }));
      }

      set((prevState) => ({
        ...prevState,
        cart,
        error: '',
      }));
      createCurrencySlice(set).getCurrencies(cart?.currency as string);
      return await Promise.resolve(cart);
    } catch (error) {
      createSettingSlice(set).setError(error);
      const location = process.env.NEXT_SWELL_PUBLIC_STORE_URL as string;
      if (!location) {
        return Promise.reject();
      }
      window.location.href = process.env.NEXT_SWELL_PUBLIC_STORE_URL as string;
      return Promise.reject();
    }
  },

  /** This function checks if order Exists
   * @returns {string}
   * @param {string} id
   */
  orderExists: async (id: string) => {
    try {
      const res = await swell.cart.getOrder(id);
      if (res) return true;
    } catch (error) {
      return false;
    }
    return false;
  },

  /** This function validate order
   * @param {string} id
   * @param {any} router
   * @returns {Promise<void>}
   */
  validateOrder: async (id: string, router: any) => {
    if (!id) throw new Error('Checkout ID not found');
    const res = await get().orderExists(id);
    if (res) {
      router.push('/order');
      window.location.reload();
      throw new Error('Order already exists');
    }
  },

  /** This function refresh billng method
   * @param {string} method
   */
  refreshBillingMethod: async (method) => {
    await get().updateCart({
      billing: {
        method: get().parseFreeMethod(method),
        zip: get().generateUniqueId(),
      },
    });
  },

  /** parse method when method is free
   * @param {string} method
   * @returns {string}
   */
  parseFreeMethod(method) {
    // !NOTE: We never want to assign free to the actual billing method in actual, we'll call it null.
    return method === 'free' ? null : method;
  },

  /** this function returns the value of `method` if it is not null or undefined, otherwise it returns
   * the string 'None'.
   * @param {string} method
   */
  getOrderBillingMethod(method) {
    return method ?? 'None';
  },

  /**
   * this function validate discounts
   * @param {string} method
   * @returns {Promise<void>}
   */
  validateDiscount: async (method: string) => {
    const { cart, checkoutSettings } = get();
    const { discounts } = cart;
    const getDiscounts = (discounts ?? []).filter(
      (discount) => discount?.manual
    );
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { payment_methods } = checkoutSettings;
    const filteredDiscount = payment_methods?.find(
      (item: any) => item.value === method
    );
    const isDiscount = getDiscounts?.some(
      // eslint-disable-next-line eqeqeq
      (item: any) =>
        parseInt(item?.id, 10) === parseInt(filteredDiscount?.discount.id, 10)
    );
    if (
      getDiscounts?.length !== 0 &&
      method !== 'free' &&
      !isDiscount &&
      filteredDiscount?.value !== method
    ) {
      await get().refreshBillingMethod(method);
      throw new Error('Discount is not valid for this payment method');
    }
  },

  /**
   * this function show paypal banner
   * @returns {boolean}
   */
  displayPaypal: () => {
    const { cart, settings } = get();
    type MethodType = { id: string; name: string };

    const onlyAccountCredit =
      // FIX: accountCreditAmount by Swell will get updated once the payment methods are rendered
      cart?.grandTotal <= cart?.account?.balance && cart?.accountCreditApplied;
    const hasPaypal = cart?.billing?.method === 'paypal';
    const paypalIsMethod = (
      settings?.paymentMethods as Array<MethodType>
    )?.find((method) => method.id === 'paypal');

    return (
      !onlyAccountCredit &&
      paypalIsMethod &&
      (hasPaypal || cart?.grandTotal > 0)
    );
  },
  /**
   * this function show prompt when user change billing method
   * @returns {boolean}
   */
  checkIfPaypal: () => {
    const { cart } = get();
    const { method } = cart.billing;
    if (method === 'paypal') {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const confirmation = confirm(
        'Changing your billing method will disconnect Paypal. Do you still to continue?'
      );
      return confirmation === true;
    }
    return true;
  },

  /**
   * this function remove paypal and update billing method
   * @returns {void}
   */
  removePaypal: () => {
    set((prevState) => ({
      ...prevState,
      cart: {
        ...prevState.cart,
        billing: {
          ...prevState.cart.billing,
          method: null,
          paypal: null,
        },
      },
    }));
  },

  /**
   * this function validate cart
   * @param {string} id
   * @param {any} router
   * @param {string} paymentMethod
   */
  validateCartPayload: async (
    id: string | null,
    router: SingletonRouter,
    paymentMethod: string
  ) => {
    try {
      await get().validateOrder(id as string, router);
      if (!router.pathname.includes('invoice')) {
        get().validateDiscount(paymentMethod);
      }
    } catch (error) {
      // @ts-ignore
      throw new Error(error.message);
    }
  },

  /**
   * this function empty the cart
   */
  emptyCart: () => {
    set((prevState) => ({
      ...prevState,
      cart: null,
    }));
  },

  /**
   * this function submit invoice
   * @param {string} card
   * @returns {Promise<{ success: boolean, message: string }>}
   */
  submitInvoice: async (card: string) => {
    const { cart } = get();
    const { id } = cart;
    const method = 'card';
    if (!id) throw new Error('Cart not found');
    if (method === '') throw new Error('No payment method selected');
    const data = await postData(`/api/submit-invoice`, {
      id,
      method,
      card,
    });
    if (!data?.success) {
      throw new Error(data?.message || 'Something went Wrong');
    }
    return data;
  },

  /**
   * this fuction set order
   * @param {any} order
   */
  setOrder: (order) => {
    set((prevState) => ({
      ...prevState,
      order,
    }));
  },
});
