const TitleCase = (str: string) => {
  if (!str) return '';
  const splitStr = str.toLowerCase().split(' ');
  if (splitStr && splitStr.length > 0) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        // @ts-ignore
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }
  return '';
};

export default TitleCase;
