import Link from 'next/link';
import type { ErrorInfo, ReactNode } from 'react';
import React, { Component } from 'react';

import Showlog from '@/utils/logger';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Showlog('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="flex min-h-full py-24 px-8">
          <div className="flex flex-1 items-center justify-center">
            <div className="flex w-full max-w-2xl flex-col items-center px-6 text-center md:px-10">
              <h1 className="mt-16 text-[10vw] font-bold text-gray">404</h1>
              <h2 className="mt-6 text-2xl font-semibold text-gray">
                The page your were looking for could not be found.
              </h2>
              <p className="mt-4 font-medium text-text">
                It seems this page is missing. Please check the URL or go home.
              </p>
              {/**
               * TODO: Go to main store or handle error here
               */}
              <Link
                href="/"
                className="mt-8 inline-flex cursor-pointer items-center justify-center rounded-xl border-2 border-primary bg-primary px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:border-primary-accent hover:bg-primary-accent focus:outline-none focus:ring-2 focus:ring-orange-400/80 focus:ring-offset-0 disabled:opacity-30 disabled:hover:border-primary disabled:hover:bg-primary disabled:hover:text-white dark:focus:ring-white/80"
              >
                Go Home
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
