export async function postData(
  url: string = '',
  data: object = {}
): Promise<any> {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
  return response.json();
}

export async function getData(url: string = '', headers = {}): Promise<any> {
  try {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    });

    if (!response.ok || response.status !== 200)
      throw new Error('Something went wrong');
    const res = await response.json();
    if (res?.errors?.length > 0 || res.status === false)
      throw new Error(res.errors.at(0).message ?? 'Something went wrong');

    return res;
  } catch (error: any) {
    throw new Error(error);
  }
}
