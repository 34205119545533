import '../styles/global.css';

import type { AppProps } from 'next/app';
// import { useRouter } from 'next/router';
import React from 'react';

import BugSnagBoundary from '@/components/bugSnag';
import ErrorBoundary from '@/components/ErrorBoundary';
import RouterLoading from '@/components/routerLoading';
import ZustandLoad from '@/stores/ZustandLoad';

const MyApp = (props: AppProps) => {
  return (
    <React.StrictMode>
      <BugSnagBoundary>
        <ErrorBoundary>
          <ZustandLoad />
          <RouterLoading {...props} />
        </ErrorBoundary>
      </BugSnagBoundary>
    </React.StrictMode>
  );
};

export default MyApp;
